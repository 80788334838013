import { getReportData } from "@src/service";
import { call } from "redux-saga/effects";
import { ReportDataType } from "../types";

/**
 * @author pp jo <pp.jo@earlysloth.com>
 * @description 리포트 데이터 JSON을 가져오는 함수
 * @param {string} namespace 계정 username
 * @param {string} urlToken 보고서 고유 url token
 * @param {string} password 보고서 비밀번호
 * @returns 리포트 데이터 JSON을 반환
 */
function* getReportDataWorker(
  namespace: string,
  urlToken: string,
  password?: string
) {
  const data: [ReportDataType, number] = yield call(
    getReportData,
    namespace,
    urlToken,
    password
  );
  return data;
}

export default getReportDataWorker;
