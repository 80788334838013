import { call } from "redux-saga/effects";
import {
  setBlockDataWorker,
  setSingleDataFilterBlockDataWorker,
  setSingleFrequencyBlockDataWorker,
} from ".";
import {
  RadioChartDataType,
  SingleBlockChartDataType,
  SingleBlockContentType,
  SingleFreqType,
} from "../types";

function* getSingleBlockChartData(
  ABlock: SingleBlockContentType,
  namespace: string,
  urlToken: string,
  isMulti: boolean,
  itemIndex?: number,
  singleBlockIndex?: number
) {
  if (ABlock.blockInfo!.frequencyUnit != undefined) {
    // 추이분석일 때
    const chartData: SingleFreqType = yield call(
      setSingleFrequencyBlockDataWorker,
      namespace,
      urlToken,
      ABlock,
      isMulti
    );

    return chartData;
  } else if (
    ABlock.blockInfo &&
    ABlock.blockInfo?.filter?.q2 &&
    ABlock.blockInfo.filter.filterAnswerIndex !== null
  ) {
    // 데이터 필터를 걸었을  때
    const chartData: RadioChartDataType = yield call(
      setSingleDataFilterBlockDataWorker,
      namespace,
      urlToken,
      ABlock,
      isMulti
    );

    return chartData;
  } else {
    // 일반분석일 때
    const chartData: SingleBlockChartDataType = yield call(
      setBlockDataWorker,
      namespace,
      urlToken,
      ABlock,
      isMulti,
      itemIndex,
      singleBlockIndex
    );

    return chartData;
  }
}

export default getSingleBlockChartData;
