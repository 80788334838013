import React from 'react';
import { SingleBlockType, SingleBlockChartDataType, RadioChartDataType } from '../../modules/types'
import { Chart } from '@earlysloth/pocketsurvey-ui-components'

export const makexAxis = (blocks: SingleBlockType[]) => {
  const xAxisArr: string[] = [];
  blocks.map((item, index: number) => {
    xAxisArr.push((item.data as RadioChartDataType).question);
  });
  return xAxisArr;
};

const makeSingleSeries = (blocks: SingleBlockType[]) => {
  const dataArr: any[] = [];
  blocks.map((item: any, index: number) => {
    dataArr.push(item.data.score);
  });

  return {
    dataArr,
    markLine: {
      symbol: 'none',
      data: [{ type: 'average', name: '' }],
      label: {
        show: true,
        formatter: ' {c}',
        position: 'middle',
        fontSize: 14,
      },
      lineStyle: {
        color: '#000000',
        type: 'solid',
        width: 1.5,
      },
    },
  };
};

const makeLegend = (blocks: SingleBlockType[]) => {
  const legendArr: string[] = [];
  blocks.map((item: SingleBlockType, index: number) => {
    legendArr.push(
      (item.data as SingleBlockChartDataType).question,
    );
  });
  return legendArr;
};

const getOption = (blocks: SingleBlockType[]) => {
  const {
    dataArr,
    markLine
  } = makeSingleSeries(blocks)
  const override = {
    legend: {
      data: makeLegend(blocks),
    },
    yAxis: [
      {
        type: 'value',
        name: '점수',
        nameLocation: 'middle',
        nameGap: 25,
        min: 0,
        max: 100,
        interval: 10,
      }, {
        opposite: true,
        type: 'value',
        name: '평균 점수',
        min: 0,
        max: 100,
        position: 'middle',
        interval: 10,
        axisLine: {
          onZero: 0,
        },
        nameLocation: 'middle',
        nameGap: 30,
      },
    ],
    series: [{
      markLine,
    }],
  };
  return {
    series: dataArr,
    labels: makexAxis(blocks),
    override,
  };
};

type SingleBarChartPropsType = {
  data: SingleBlockType[]
  colors?: string[]
  labelOption?: "dynamic" | "fixed"
}

const SingleBarChart = ({ data, colors, labelOption }: SingleBarChartPropsType) => {
  const option = getOption(data)
  return (
    <Chart.BarVerticalBase
      series={option.series}
      labels={option.labels}
      override={option.override}
      width={'482px'}
      height={'330px'}
    />
  );
}

export default SingleBarChart