import React from "react";
import styled from "styled-components";
import { TextBlockType } from "@src/modules/types";

const TextDiv = styled.div`
  word-break: break-word;
  box-sizing: border-box;
  margin: 0 14px;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 700;
  padding-top: 14px;
  padding-bottom: 14px;
  h1 {
    margin: 0px;
  }
  h2 {
    margin: 0px;
  }
  h3 {
    margin: 0px;
  }
`;

function Text({ data }: TextBlockType) {
  return <TextDiv dangerouslySetInnerHTML={{ __html: data.text }}></TextDiv>;
}

export default Text;
