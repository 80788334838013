import React from "react";
import { SingleFreqType } from "../../../modules/types";
import { Chart } from "@earlysloth/pocketsurvey-ui-components";

type FrequencyChartPropTypes = {
  data: SingleFreqType;
  colors: string[];
  labelOption: "dynamic" | "fixed";
  frequencyRange?: string[];
};

function FrequencyChart({
  data,
  colors,
  labelOption,
  frequencyRange,
}: FrequencyChartPropTypes) {
  let series: number[][] = data[0].labels.map(() => []);
  data.map((item, index) => {
    if (item) {
      item.series!.map((value, percentIndex) => {
        series[percentIndex].push(value);
      });
    } else {
      data[0].labels.map((score, scoreIndex) => series[scoreIndex].push(0));
    }
  });

  var wholeDataArr: any = [];
  series.map((dataArr) => {
    var dataNumber: any = [];
    dataArr.map((number) => {
      number === 0 ? dataNumber.push(null) : dataNumber.push(number);
    });
    wholeDataArr.push(dataNumber);
  });

  wholeDataArr.map((item: any, index: number) => ({
    value: item,
    itemStyle: { color: colors[index] },
    label: { show: true, color: "#000000", align: "left" },
  }));

  const frequencySeries = wholeDataArr;
  const frequencyLabels = wholeDataArr.map(
    (item: any, index: number) => data[0].labels[index]
  );

  const override = {
    yAxis: {
      max: 100,
    },
  };

  return (
    <Chart.BarVerticalStacked
      series={frequencySeries}
      labels={frequencyLabels}
      xAxisLabel={frequencyRange ?? []}
      width={"482px"}
      height={"330px"}
      hundredPercent={{
        series: true,
        tooltip: true,
      }}
      override={override}
    />
  );
}

export default FrequencyChart;
