import React, { useState, useEffect } from "react";
import {
  CheckChartDataType,
  RadioChartDataType,
} from "../../../modules/types";
import { Chart } from "@earlysloth/pocketsurvey-ui-components";

type BarRankChartPropTypes = {
  chartData: RadioChartDataType | CheckChartDataType;
  selectionRankingIndex?: {
    [key: string]: number;
  } | null;
  categorizedRankingStacks?: number[][];
  direction: "horizontal" | "vertical";
  xAxis: {
    name?: string;
    type: "category" | "value" | "time";
    data?: string[];
    axisTick?: object;
  }[];
  yAxis: {
    name?: string;
    type: "category" | "value" | "time";
    data?: string[];
    axisTick?: object;
  }[];
  colors?: string[];
  labelOption: "dynamic" | "fixed";
};

function BarRankChart({
  chartData: {
    question,
    series,
    labels,
    weightedRankingScores,
    proportion,
    selectionRankingIndex,
    categorizedRankingStacks,
  },
  direction,
  xAxis,
  yAxis,
  colors,
  labelOption,
}: BarRankChartPropTypes) {
  const [priorityCategories, setPriorityCategories] = useState<any>(null);
  const [prioritySeries, setPrioritySeries] = useState<any>(null);
  const [prioritiesDone, setPrioritiesDone] = useState<boolean>(false);

  const [sortedSeries, setSortedSeries] = useState<any>();
  const [pointsSeries, setPointsSeries] = useState<any>();

  const [chartShape, setShape] = useState(0);
  const [hasPriority, setHasPriority] = useState(false);

  let mapped = null;
  var dataArr: any = [];
  series.map((number) => {
    number === 0 ? dataArr.push(null) : dataArr.push(number);
  });

  if (
    series !== undefined &&
    labels !== undefined &&
    proportion !== undefined
  ) {
    mapped = labels.map((el, i) => {
      return {
        index: i,
        labels: el,
        series: series[i],
        proportion: proportion[i],
      };
    });
  }
  let orderedSeries: number[] = [];
  let orderedLabels: string[] = [];
  let orderedProportion: string[] = [];

  if (mapped) {
    mapped.sort((a, b) => {
      return +(b.series - a.series);
    });
    mapped.map((item) => {
      orderedSeries.push(item.series);
      orderedLabels.push(item.labels);
      orderedProportion.push(item.proportion);
    });
  }

  let newOrderedSeries: number[] = [];
  orderedSeries.map((item) => {
    if (item === undefined) {
      newOrderedSeries.push(0);
    } else {
      newOrderedSeries.push(item);
    }
  });

  useEffect(() => {
    const setPriorities = async () => {
      if (weightedRankingScores) {
        let newPriorityCategories = [];
        for (let i = 0; i < weightedRankingScores.sel_ranges.max; i++) {
          newPriorityCategories.push(`${i + 1}순위`);
        }
        await setPriorityCategories(newPriorityCategories);
        let newPrioritySeries = [];
        for (let i = 0; i < labels.length; i++) {
          newPrioritySeries.push({
            name: labels[i],
            //@ts-ignore
            data: Array.apply(null, {
              length: weightedRankingScores.sel_ranges.max,
            }).map(
              (selections, selIndex) =>
                //@ts-ignore
                weightedRankingScores[`selr_${selIndex + 1}`][i]
            ),
          });
        }
        await setPrioritySeries(newPrioritySeries);

        await setPrioritiesDone(true);
      }
    };
    setPriorities();
  }, []);

  useEffect(() => {
    const priorityCheck = async () => {
      if (prioritySeries && priorityCategories && selectionRankingIndex) {
        await setHasPriority(true);
        await setShape(3);
      } else {
        if (prioritySeries && priorityCategories) {
          await setHasPriority(false);
          await setShape(0);
        }
      }
    };
    if (prioritiesDone) {
      priorityCheck();
      if (prioritySeries) {
        let pointedPrioritySeries = prioritySeries.map(
          (series: any, index: number) => ({
            ...series,
            points: weightedRankingScores!.weight_score_100[index],
          })
        );
        let orderedPrioritySeries = pointedPrioritySeries.sort(
          (a: any, b: any) => {
            return +(b.points - a.points);
          }
        );
        let points: number[] = [];
        let notPointedOrderedPrioritySeries = orderedPrioritySeries.map(
          (item: any) => {
            points.push(item.points);
            return { name: item.name, data: item.data };
          }
        );
        setSortedSeries(orderedPrioritySeries);
        setPrioritySeries(notPointedOrderedPrioritySeries);
        setPointsSeries(points);
      }
    }
  }, [prioritiesDone]);

  const getVerticalChartOption = (
    orderedLabels: string[],
    newOrderedSeries: number[],
    colors?: string[]
  ) => {
    const option: any = {};
    option.tooltip = {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
      position:
        labelOption == "fixed"
          ? function (pos: any, params: any, el: any, elRect: any, size: any) {
              if (labelOption === "fixed") {
                var obj: any = { top: 10 };
                obj[["left", "right"][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                return obj;
              }
            }
          : [10, 10],
    };
    option.xAxis = {
      type: "category",
      data: orderedLabels,
      axisLabel: {},
      show: true,
    };
    option.yAxis = { type: "value", show: true };
    const dataArr: any = [];
    newOrderedSeries.map((number, index) => {
      dataArr.push({
        value: number,
        // itemStyle: { color: colors![index] },
      });
    });
    option.series = [
      {
        data: dataArr,
        type: "bar",
        label: {
          show: true,
          color: "#000000",
        },
      },
    ];
    option.tooltip = {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    };
    option.grid = {
      height: "60%",
    };
    return option;
  };

  const getPointChartOption = (
    sortedSeries: any,
    pointsSeries: number[],
    colors?: string[]
  ) => {
   const series = pointsSeries;
    const axisLabel = sortedSeries.map((item: any) => item.name);
    const label = sortedSeries.map((item: any) => item.name);
    return {
      series,
      axisLabel,
      label,
    };
  };
  if (sortedSeries) {
    const option = getPointChartOption(sortedSeries, pointsSeries, colors);
    const seriesData = prioritySeries.map((item: any) => item.data);
    const labelsData = prioritySeries.map((item: any) => item.name);
    return (
      <> 
        {direction === "horizontal" && (
          <Chart.BarHorizontalStacked
          //@ts-ignore
            series={seriesData}
            labels={labelsData}
            yAxisLabel={priorityCategories}
            width={"482px"}
            height={"300px"}
          />
        )}
        {direction === "vertical" && (
          <Chart.BarVerticalStacked
          //@ts-ignore
            series={seriesData}
            labels={labelsData}
            xAxisLabel={priorityCategories}
            width={"482px"}
            height={"300px"}
          />
        )}
      </>
    );
  } else {
    return null;
  }
}

export default BarRankChart;
