import React from "react";
import ReactECharts from "echarts-for-react";
import {
  CheckChartDataType,
  RadioChartDataType,
} from "../../../modules/types";
import { Chart } from '@earlysloth/pocketsurvey-ui-components'

type PieChartPropTypes = {
  chartData: RadioChartDataType | CheckChartDataType;
  showImage?: boolean;
  colors?: string[];
  labelOption: "dynamic" | "fixed";
};

function PieChart({
  chartData: { question, series, labels, image_selections, score },
  showImage,
  colors,
  labelOption,
}: PieChartPropTypes) {
  console.log('score: ', score);
  console.log('showImage: ', showImage);
  if (!showImage) {
    return (
      <Chart.PieBase
        width={"90%"}
        series={series}
        labels={labels}
        showLabel={false}
        hasScore={!!score}
      />
    );
  } else {
    return (
      <ReactECharts
        option={{
          tooltip: {
            trigger: "item",

            position:
              labelOption == "fixed"
                ? function (
                  pos: any,
                  params: any,
                  el: any,
                  elRect: any,
                  size: any
                ) {
                  if (labelOption === "fixed") {
                    var obj: any = { top: 10 };
                    obj[
                      ["left", "right"][+(pos[0] < size.viewSize[0] / 2)]
                    ] = 30;
                    return obj;
                  }
                }
                : [10, 10],
          },
          series: [
            {
              type: "pie",
              radius: "80%",
              data: series.map((item, index) => {
                let seriesImage: HTMLImageElement = new Image();
                seriesImage.src = image_selections![index];

                if (item != 0) {
                  return {
                    value: item,
                    name: labels[index],
                    itemStyle: {
                      normal: {
                        opacity: 0.7,
                        color: {
                          image: seriesImage,
                          repeat: "repeat",
                        },
                      },
                    },
                    label: {
                      normal: {
                        show: true,
                        position: "inside",
                        formatter: "{c}", //The template variables are {a}, {b}, {c}, {d}, which respectively represent series name, data name, data value, and percentage. {d}The data will calculate the percentage based on the value
                      },
                    },
                  };
                }
              }),
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        }}
      />
    );
  }
}

export default PieChart;
