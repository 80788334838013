import { getBlockDataFromCallHash } from "@src/service";
import { call } from "redux-saga/effects";
import { SingleBlockContentType, SingleBlockType } from "../types";

/**
 * @author pp jo <pp.jo@earlysloth.com>
 * @description 리포트 데이터에 있는 contents를 순회하면서 얻은 block을 이용해서 블록을 렌더하기 위해 필요한 데이터를 세팅하는 함수
 * @param {string} namespace 계정 username
 * @param {string} urlToken 보고서 고유 url token
 * @param {ContentsType} block 블록 데이터
 * @param {number} index 블록 순서
 */
function* setBlockDataWorker(
  namespace: string,
  urlToken: string,
  block: SingleBlockContentType,
  isMulti: boolean,
  blockIndex?: number,
  singleBlockIndex?: number
) {
  const [data, status]: [any, number] = yield call(
    getBlockDataFromCallHash,
    namespace,
    urlToken,
    block.callHash[0],
    block.surveyId
  );

  let dummyChartData = {};
  if (block.questionType === "shorttext") {
    dummyChartData = {
      phonenum: [],
      question: "",
      questionNum: 0,
      scale: [],
      series: [],
      tags: [],
      tags_kv: [],
      type: "shorttext",
      uuid: [],
    };
  } else {
    dummyChartData = {
      question: "",
      questionNum: 0,
      type: "radio",
      series: [0, 0],
      labels: ["", ""],
      proportion: ["100.0%", "0.0%"],
      elapsed: ["3.4", "0.0"],
      image_selections: [],
    };
  }

  const hasQuestionOrders = Object.hasOwnProperty.call(data, "question_orders");
  if (hasQuestionOrders) {
    const parsedData = data[data.question_orders[0]];
    return parsedData ?? dummyChartData;
  }
  if (
    Object.hasOwnProperty.call(data, "counts") &&
    Object.hasOwnProperty.call(data, "ratios") &&
    Object.hasOwnProperty.call(data, "scores")
  ) {
    return data;
  }

  // if (isMulti) {
  //   if (data === null) {
  //     yield put(sandboxActionsCreators.addblockArr(dummyBlock));
  //   } else {
  //     console.log("세팅된 블록 저장");
  //     yield put(sandboxActionsCreators.addblockArr(newBlock));
  //   }
  // } else {
  //   // 비교 블록 저장 액션 호출
  //   yield put(
  //     sandboxActionsCreators.setChartDataInMultiBlock({
  //       newBlock,
  //       blockIndex: blockIndex!,
  //       singleBlockIndex: singleBlockIndex!,
  //     })
  //   );
  // }
}

export default setBlockDataWorker;
