import React from "react";
import {
  SingleBlockType,
  SingleBlockChartDataType,
  RadioChartDataType,
} from "../../modules/types";
import { Chart } from "@earlysloth/pocketsurvey-ui-components";

export const makexAxis = (blocks: SingleBlockType[]) => {
  const xAxisArr: string[] = [];
  blocks.map((item, index: number) => {
    xAxisArr.push((item.data as RadioChartDataType).question);
  });
  return xAxisArr;
};

export const makeData = (blocks: SingleBlockType[]) => {
  const makeDataArr = (i: number) => {
    const dataArr: any[] = [];
    blocks.map((item, index: number) => {
      const stringP = parseInt(
        ((item.data as RadioChartDataType).proportion as string[])[i].split(
          "%"
        )[0]
      );
      dataArr.push(stringP !== 0 ? stringP : null);
    });
    return dataArr;
  };

  const series: number[][] = [];
  const labels: string[] = [];
  for (
    let i = 0;
    i < (blocks[0].data as SingleBlockChartDataType).series.length;
    i++
  ) {
    series.push(makeDataArr(i));
    labels.push((blocks[0].data as RadioChartDataType).labels[i]);
  }
  return {
    series,
    labels,
  };
};

const getOption = (blocks: SingleBlockType[]) => {
  const chartOptions = makeData(blocks);

  const override = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {},
      show: true,
      min: 0,
      max: 100,
      interval: 10,
    },
  };

  return {
    labels: chartOptions ? chartOptions.labels : [],
    series: chartOptions ? chartOptions.series : [],
    xAxisLabel: makexAxis(blocks),
    override,
  };
};

type MixedChartPropsType = {
  data: SingleBlockType[];
  colors?: string[];
  labelOption?: "dynamic" | "fixed";
};

const MultiplebarA = ({ data, colors, labelOption }: MixedChartPropsType) => {
  const option = getOption(data);
  return (
    <> 
      <Chart.BarVerticalStacked
        series={option.series}
        labels={option.labels}
        xAxisLabel={option.xAxisLabel}
        override={option.override}
        width={"482px"}
        height={"330px"}
        hundredPercent={{
          series: false,
          tooltip: true,
        }}
      />
    </>
  );
};

export default MultiplebarA;
