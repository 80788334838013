import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { CheckChartDataType, RadioChartDataType } from "../../../modules/types"

const Table = styled.div`
  width: 100%;
  margin: 42px auto;
  box-sizing: border-box;
`
const TableHeader = styled.div`
  width: 100%;
  background-color: rgb(251, 249, 247);
  box-sizing: border-box;
  display: flex;
  height: 42px;
  align-items: center;
  div {
    flex: 1;
    display: flex;
    justify-content: center;
    border-collapse: collapse;
    align-items: center;
    height: 100%;
  }
  div:first-child {
    position: relative;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><line x1="0" y1="0" x2="100%" y2="100%" stroke="rgb(250,198,6)" fill="rgb(251,249,247)"/></svg>');
    span {
      position: absolute;
      &:first-child {
        right: 0;
        top: 0;
        // color: #59c4db;
      }
      &:nth-child(2) {
        left: 0;
        bottom: 0;
        // color: #f2ab27;
      }
    }
  }
`

const TableLine = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  div {
    box-sizing: border-box;
    justify-content: center;
    display: flex;
    align-items: center;
    height: 42px;
    flex: 1;
    border-collapse: collapse;
    border-top: 1px solid beige;
    text-align: left;
    text-overflow: ellipsis;
  }
  div:first-child {
    background-color: rgb(251, 249, 247);
    div {
      text-overflow: ellipsis;
      justify-content: flex-start;
    }
  }
`
type RankTablePropTypes = {
  chartData: RadioChartDataType | CheckChartDataType
  colors?: string[]
}

function RankTable({ chartData: { series, labels, proportion, elapsed, question, weightedRankingScores, selectionRankingIndex, categorizedRankingStacks }, colors }: RankTablePropTypes) {
  const [priorityCategories, setPriorityCategories] = useState<any>(null)
  const [prioritySeries, setPrioritySeries] = useState<any>(null)
  const [prioritiesDone, setPrioritiesDone] = useState<boolean>(false)

  const [sortedSeries, setSortedSeries] = useState<any>()
  const [pointsSeries, setPointsSeries] = useState<any>()

  const [chartShape, setShape] = useState(0)
  const [hasPriority, setHasPriority] = useState(false)
  useEffect(() => {
    const setPriorities = async () => {
      if (weightedRankingScores) {
        let newPriorityCategories = []
        for (let i = 0; i < weightedRankingScores.sel_ranges.max; i++) {
          newPriorityCategories.push(`${i + 1}순위`)
        }
        await setPriorityCategories(newPriorityCategories)
        let newPrioritySeries = []
        for (let i = 0; i < labels.length; i++) {
          newPrioritySeries.push({
            name: labels[i],
            //@ts-ignore
            data: Array.apply(null, {
              length: weightedRankingScores.sel_ranges.max,
            }).map(
              (selections, selIndex) =>
                //@ts-ignore
                weightedRankingScores[`selr_${selIndex + 1}`][i]
            ),
          })
        }
        await setPrioritySeries(newPrioritySeries)

        await setPrioritiesDone(true)
      }
    }
    setPriorities()
  }, [])

  useEffect(() => {
    const priorityCheck = async () => {
      if (prioritySeries && priorityCategories && selectionRankingIndex) {
        await setHasPriority(true)
        await setShape(3)
      } else {
        if (prioritySeries && priorityCategories) {
          await setHasPriority(false)
          await setShape(0)
        }
      }
    }
    if (prioritiesDone) {
      priorityCheck()
      if (prioritySeries) {
        let pointedPrioritySeries = prioritySeries.map((series: any, index: number) => ({
          ...series,
          points: weightedRankingScores!.weight_score_100[index],
        }))
        let orderedPrioritySeries = pointedPrioritySeries.sort((a: any, b: any) => {
          return +(b.points - a.points)
        })
        let points: number[] = []
        let notPointedOrderedPrioritySeries = orderedPrioritySeries.map((item: any) => {
          points.push(item.points)
          return { name: item.name, data: item.data }
        })
        setSortedSeries(orderedPrioritySeries)
        setPrioritySeries(notPointedOrderedPrioritySeries)
        setPointsSeries(points)
      }
    }
  }, [prioritiesDone])

  const getPointChartOption = (sortedSeries: any, pointsSeries: number[]) => {
    const option: any = {}
    option.xAxis = {
      type: "value",
      axisLabel: {},
      show: true,
    }
    option.yAxis = {
      type: "category",
      data: sortedSeries.map((item: any) => item.name),
      show: true,
      inverse: true,
    }
    const dataArr: any = []
    pointsSeries.map((number, index) => {
      dataArr.push({
        value: number,
        itemStyle: { color: colors![index] },
      })
    })
    option.series = [
      {
        data: dataArr,
        type: "bar",
        label: {
          show: true,
          color: "#000000",
        },
      },
    ]
    option.tooltip = {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    }
    option.grid = {
      height: "60%",
    }
    return option
  }

  const getRankStackedChartOption = (
    priorityCategories: string[],
    prioritySeries: {
      name: string
      data: number[]
    }[]
  ) => {
    const option: any = {}
    option.xAxis = {
      type: "value",
      axisLabel: {},
      show: true,
    }
    option.yAxis = {
      type: "category",
      data: priorityCategories,
      show: true,
      inverse: true,
    }
    const dataArr: any = []
    prioritySeries.map((numbers, index) => {
      dataArr.push({
        name: numbers.name,
        type: "bar",
        stack: "total",
        label: {
          show: true,
        },
        emphasis: {
          focus: "series",
        },
        data: numbers.data,
        itemStyle: { color: colors![index] },
      })
    })

    option.series = dataArr
    option.tooltip = {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    }
    option.grid = {
      height: "60%",
    }
    return option
  }
  if (sortedSeries) {
    return (
      <Table>
        <TableHeader>
          <div>
            <span>순위</span>
            <span>선택지</span>
          </div>
          {priorityCategories.map((prize: any) => {
            return <div key={prize}>{prize}</div>
          })}
          {selectionRankingIndex ? <div>평균 순위</div> : null}
          <div>가중치 환산점수</div>
        </TableHeader>
        {sortedSeries.map((item: any, seriesIndex: number) => (
          <TableLine>
            <div>{item.name}</div>
            {item.data.map((value: any) => (
              <div>{value}</div>
            ))}
            {selectionRankingIndex ? <div>{selectionRankingIndex[item.name]}</div> : null}
            <div>{`${item.points}%`}</div>
          </TableLine>
        ))}
      </Table>
    )
  } else {
    return null
  }
}

export default RankTable
