import React from 'react'
import { SingleBlockType, CrossTableChartType, RadioChartDataType } from '../../modules/types'
import { Chart } from '@earlysloth/pocketsurvey-ui-components'
type MultiplebarBPropsType = {
  data: (SingleBlockType | CrossTableChartType)[]
  type: "multiplebar/B" | "separated"
}

const reducer = (accumulator: number, currentValue: number) => accumulator + currentValue;

const makePercent = (multiChartData: any) => {
  const wrapper: any[] = [];
  multiChartData.map((item: number[], i: number) => {
    const pArr: any[] = [];
    item.map((data: number, index: number) => {
      if (data !== 0) {
        pArr.push(parseFloat(((data / item[item.length - 1]) * 100).toFixed(1)));
      } else {
        pArr.push(null);
      }
    });
    pArr.pop();
    wrapper.push(pArr);
  });
  return wrapper;
};

const makeData = (multiChartData: any, blocks: any) => {
  const percentArr = makePercent(multiChartData);
  const series: number[][] = [];
  const labels: string[] = [];

  for (let i = 0; i < percentArr[0].length; i++) {
    const tempArr: number[] = [];
    for (let k = 0; k < percentArr.length - 1; k++) {
      tempArr.push(percentArr[k][i]);
    }
    series.push(tempArr);
    labels.push(blocks.labels[i]);
  }

  return {
    series,
    labels,
  };
};


const getOption = (blocks: RadioChartDataType, multiChartData: number[][]) => {
  const chartOptions = makeData(multiChartData, blocks);

  const override = {
    yAxis: {
      type: 'value',
      axisLabel: {},
      show: true,
      min: 0,
      max: 100,
      interval: 10,
    },
    // legend: {
    //   data: blocks[1].question !== null ? blocks[1].question.body : null,
    // },

    // tooltip: {
    //   trigger: 'axis',
    //   axisPointer: {
    //     type: 'shadow',
    //   },
    // },
  };

  return {
    series: chartOptions.series,
    labels: chartOptions.labels,
    override,
  };
};


const MultiplebarB = ({ data, type }: MultiplebarBPropsType) => {
  //data의 첫 데이터는 crosstable 데이터임
  const multiChartData = (data[0] as CrossTableChartType)?.counts?.table_data ?? null
  if (!multiChartData) return null
  const blocksData = (data[2] as SingleBlockType).data
  const option = getOption((blocksData as RadioChartDataType), multiChartData);
  //@ts-ignore
  const xAxisLabel = data[1].data.labels

  if (type === 'multiplebar/B') {
    return <Chart.BarVerticalStacked
      series={option.series}
      labels={option.labels}
      override={option.override}
      xAxisLabel={xAxisLabel}
      width={"482px"}
      height={'330px'}
      hundredPercent={{
        series: false,
        tooltip: true,
      }}
    />
  }

  if (type === 'separated') {
    return (
      <Chart.BarVerticalSeparated
        series={option.series}
        label={xAxisLabel}
        xAxisLabel={option.labels}
        override={option.override}
        width={"482px"}
        height="420px"
        hundredPercent={{
          series: false,
          tooltip: false,
        }}
      />
    );
  }

  return null;
}

export default MultiplebarB