import { getBlockDataFromCallHash } from "@src/service";
import { call } from "redux-saga/effects";
import { SingleBlockContentType } from "../types";

function* setSingleDataFilterBlockDataWorker(
  namespace: string,
  urlToken: string,
  block: SingleBlockContentType,
  isMulti: boolean
) {
  try {
    const [data, status]: [any, number] = yield call(
      getBlockDataFromCallHash,
      namespace,
      urlToken,
      block.callHash[0],
      block.surveyId
    );

    return data[data.question_orders[0]];
  } catch (error) {
    console.error("세팅된 블록 실패: 에러", error);
  }
}

export default setSingleDataFilterBlockDataWorker;
