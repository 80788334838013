import axios from "axios"

const currentURL = window.location.href
var env

if (currentURL.startsWith("https://report-view.pocketsurvey.co.kr/")) {
  env = "prod"
} else {
  env = "prod"
}

const Api = axios.create({
  baseURL: `https://1fqtgnquh6.execute-api.ap-northeast-2.amazonaws.com/${env}`,
})

export default Api
