import React from "react"
import { ShorttextChartDataType } from "@src/modules/types"
import styled from "styled-components"

const ShorttextChartContainer = styled.div`
  box-sizing: border-box;
  overflow-y: scroll;
`
const ShorttextItemContainer = styled.div`
  font-size: 14px;
  text-align: center !important;
  border: 1px solid #dfdedd;
  border-radius: 5px;
  margin-bottom: 7px;
  padding: 14px;
  font-family: "Noto Sans KR", sans-serif;
`

const ShoretextItemHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 7px;
`
const PhoneNumberBox = styled.div`
  text-align: center !important;
  font-weight: 500;
`
const AnswerBox = styled.div`
  text-align: left;
`
const ScoreSection = styled.span`
  padding: 0 10px;
  border-radius: 11px;
  color: #2b2e33;
  background-color: #dfdedd;
  font-weight: 500;
`

type ShorttextChartPropTypes = {
  data: ShorttextChartDataType
}

function ShorttextChart({ data }: ShorttextChartPropTypes) {
  return (
    <ShorttextChartContainer>
      {data.series.map((item, index) => (
        <ShorttextItemContainer>
          <ShoretextItemHeader>
            <ScoreSection>{data.scale[index].overall_score_100}</ScoreSection>
            {/* <PhoneNumberBox>{data.phonenum[index]}</PhoneNumberBox> */}
          </ShoretextItemHeader>
          <AnswerBox>{item}</AnswerBox>
        </ShorttextItemContainer>
      ))}
    </ShorttextChartContainer>
  )
}

export default ShorttextChart
