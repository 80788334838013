import { ChartType } from "../modules/types";

function switchChartType(type: string) {
  switch (type) {
    case ChartType.pie:
      return ChartType.pie;
    case ChartType.pieImage:
      return ChartType.pieImage;
    case ChartType.barHorizontal:
      return ChartType.barHorizontal;
    case ChartType.barVertical:
      return ChartType.barVertical;
    case ChartType.barImage:
      return ChartType.barImage;
    case ChartType.line:
      return ChartType.line;
    case ChartType.nps:
      return ChartType.nps;
    case ChartType.ratioTable:
      return ChartType.ratioTable;
    case ChartType.rankTable:
      return ChartType.rankTable;
    case ChartType.gauge:
      return ChartType.gauge;
    case ChartType.barHorizontalPriority:
      return ChartType.barHorizontalPriority;
    case ChartType.barVerticalPriority:
      return ChartType.barVerticalPriority;
    case ChartType.priorityBarHorizontal:
    case ChartType.barHorizontalRank:
      return ChartType.barHorizontalRank;
    case ChartType.priorityBarVertical:
    case ChartType.barVerticalRank:
      return ChartType.barVerticalRank;
    case ChartType.shorttext:
      return ChartType.shorttext;
    case ChartType.shorttextImage:
      return ChartType.shorttextImage;
    case ChartType.shorttextVideo:
      return ChartType.shorttextVideo;
    default:
      break;
  }
}

export default switchChartType;
