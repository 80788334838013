import React from 'react'
import styled from 'styled-components'
import ReactECharts from 'echarts-for-react'
/*
필요한 데이터
1. 차트 이름
2. 데이터 이름
3. 데이터 값
4. 컬러
*/
const GaugeContainer = styled.div<{ color: string }>`
  width: 100%;
  text-align: center;
  margin: 30px auto;
  border: 1px dashed ${props => props.color};
`
type GaugeChartProps = {
  chartName: string
  dataName: string
  dataValue: number //기존 차트의 (props.item.score + 100) / 2 값
  color: string
  height: number
}
function GaugeChart({
  chartName,
  dataName,
  dataValue,
  color,
  height,
}: GaugeChartProps) {
  let option = {
    tooltip: {
      formatter: '{a} <br/>{b} : {c}',
    },
    series: [
      {
        type: 'gauge',
        name: chartName,
        progress: {
          show: true,
          width: 20,
          itemStyle: {
            // borderType: 'dotted',
            // borderColor: 'green',
            // borderWidth: 3
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: color, // color at 0% position
                },
                {
                  offset: 1,
                  color: color, // color at 100% position
                },
              ],
              global: false, // false by default
            },
          },
        },
        axisLine: {
          lineStyle: {
            width: 20,
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          length: 0,
          // lineStyle: {
          //   width: 2,
          //   color: "#999",
          // },
        },
        axisLabel: {
          distance: 20,
          color: '#999',
          fontSize: 25,
        },
        anchor: {
          show: true,
          showAbove: true,
          size: 20,
          itemStyle: {
            borderWidth: 5,
            borderColor: color,
            //   shadowColor: "rgba(0, 0, 0, 0.5)",
            //   shadowBlur: 10,
          },
        },
        pointer: {
          //게이지 침
          show: true,
          width: 8,
          icon: 'rect',
          length: '50%',
          itemStyle: {
            color: color,
            //   shadowColor: "rgba(0, 0, 0, 0.5)",
            //   shadowBlur: 10,
          },
          //   'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none'
        },
        title: {
          show: true,
        },
        detail: {
          valueAnimation: true,
          fontSize: 20,
          offsetCenter: [0, '40%'],
        },
        data: [
          {
            name: dataName,
            value: dataValue,
          },
        ],
      },
    ],
  }
  return (
    <GaugeContainer color={color}>
      <ReactECharts
        option={option}
        notMerge={true}
        lazyUpdate={true}
        style={{ height: `${height}px` }}
        className="gaugeChart"
      />
    </GaugeContainer>
  )
}

export default GaugeChart
