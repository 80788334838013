import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  SingleBlockType,
  TextBlockType,
} from "./modules/types";
import { RootState } from "./modules";
import { SingleDataChart, Text } from "./components";
import { switchChartType } from "./util";
import { switchMultiChart } from './components/MultiDataChart'
import { sandboxActionsCreators } from "./modules/sandbox";
import "./App.scss";

const mapStateToProps = (state: RootState) => ({
  reportData: state.sandboxReducer.reportData,
  blockArr: state.sandboxReducer.blockArr,
});

const dispatchProps = {
  ...sandboxActionsCreators,
};

type SandboxType = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const BlockName = styled.h1`
  color: #2b2e33;
  font-size: 20px;
  text-align: left;
  word-break: break-word;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 500;
`;

const QuestionTitle = styled.h2`
  color: #2b2e33;
  font-size: 14px;
  text-align: left;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 400;
  margin-top: 7px;
  margin-bottom: 0px;
`;

const MultiDataChartDiv = styled.div`
  background: rgb(255, 255, 255);
  box-shadow: #dfdedd -1px 1px 5px;
  min-height: 437px;
  z-index: -1;
  border-radius: 10px;
  margin-bottom: 16px;
  padding: 28px 14px;
  @media (max-width: 301px) {
    border-radius: 0px;
  }
  @media (min-width: 301px) and (max-width: 500px) {
    border-radius: 0px;
  }
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

function App({ reportData, blockArr, fetchReportData }: SandboxType) {
  const [onlyTitle, setOnlyTitle] = useState<boolean>(false);
  const [noData, setNoData] = useState<boolean>(false);

  useEffect(() => {
    fetchReportData(window.location.toString());
  }, []);

  useEffect(() => {
    if (reportData?.contents.length === 0) {
      setOnlyTitle(true);
    } else {
      setOnlyTitle(false);
    }
  }, [reportData]);

  if (blockArr.length > 0 || onlyTitle) {
    return (
      <div className="App">
        <div className="main-container">
          <h2 className="report-title">{reportData?.title}</h2>
          <div className="contents-container">
            {blockArr?.map((info, index) => {
              if (info.blockType == "single") {
                const block = info as SingleBlockType;
                return (
                  <SingleDataChart
                    blockType="single"
                    data={block.data}
                    chartType={switchChartType(block.chartType!)!}
                    label={block.label}
                    key={index}
                    colors={block.colors!}
                    questionType={block.questionType}
                    blockInfo={block.blockInfo}
                    frequencyRange={block.frequencyRange}
                  />
                );
              } else if (info.blockType == "text") {
                const block = info as TextBlockType;
                return (
                  <Text
                    blockType="text"
                    data={block.data}
                    label={block.label}
                    key={index}
                  />
                );
              } else if (info.blockType == "multi") {
                return (
                  <div key={index} className="multi-block-container">
                    <MultiDataChartDiv>
                      <BlockName>
                        {info.label.blueprintLabel}
                      </BlockName>
                      <QuestionTitle>
                        {info.label.questionLabel}
                      </QuestionTitle>
                      {switchMultiChart(info)}
                    </MultiDataChartDiv>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default connect(mapStateToProps, dispatchProps)(App);
