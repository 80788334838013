import React from "react";
import {
  RadioChartDataType,
  CheckChartDataType,
} from "../../../modules/types";
import { Chart } from "@earlysloth/pocketsurvey-ui-components";

type BarChartPropTypes = {
  chartData: RadioChartDataType | CheckChartDataType;
  direction: "horizontal" | "vertical";
  xAxis: {
    name?: string;
    type: "category" | "value" | "time";
    data?: string[];
    axisTick?: object;
    splitLine: object;
  }[];
  yAxis: {
    name?: string;
    type: "category" | "value" | "time";
    data?: string[];
    axisTick?: object;
    splitLine: object;
  }[];
  colors: string[];
  labelOption: "dynamic" | "fixed";
};

function BarChart({
  chartData: { question, series, labels },
  direction,
  xAxis,
  yAxis,
  colors,
  labelOption,
}: BarChartPropTypes) {
  if(!series) return null
  return (
    <>
      {direction === "horizontal" && (
        <Chart.BarHorizontalBase
          width={"482px"}
          // height="330px"
          series={series}
          labels={labels}
        />
      )}

      {direction === "vertical" && (
        <Chart.BarVerticalBase
          width={"482px"}
          // height="330px"
          series={series}
          labels={labels}
        />
      )}
    </>
  );
}

export default BarChart;
