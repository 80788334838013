import React from "react"
import styled from "styled-components"
import { CheckChartDataType, RadioChartDataType } from "../../../modules/types"
import { Chart } from '@earlysloth/pocketsurvey-ui-components'

const Table = styled.div`
  width: 100%;
  margin: 42px auto;
  box-sizing: border-box;
`
const TableHeader = styled.div`
  width: 100%;
  background-color: rgb(251, 249, 247);
  box-sizing: border-box;
  display: flex;
  height: 34px;
  align-items: center;
  div {
    flex: 1;
    border-collapse: collapse;
  }
`

const TableLine = styled.div<{ color: string }>`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  div {
    box-sizing: border-box;
    justify-content: center;
    display: flex;
    align-items: center;
    height: 42px;
    flex: 1;
    border-collapse: collapse;
    border-top: 1px solid beige;
    text-align: left;
    text-overflow: ellipsis;
  }
  div:first-child {
    div {
      text-overflow: ellipsis;
      justify-content: flex-start;
    }
  }
  span {
    width: 20px;
    height: 20px;
    margin-right: 21px;
    border-radius: 50%;
    color: ${(props) => props.color};
    background-color: ${(props) => props.color};
  }
`
type RatioTablePropTypes = {
  chartData: RadioChartDataType | CheckChartDataType
  colors?: string[]
}

function RatioTable({ chartData: { series, labels, proportion, elapsed, question }, colors }: RatioTablePropTypes) {
  const colorsArr = Chart.util.getColors.barStacked(series.length)
  return (
    <Table>
      <TableHeader>
        <div></div>
        <div>답변수</div>
        <div>비중</div>
        {elapsed && elapsed!.length > 0 ? <div>평균응답시간(초)</div> : null}
      </TableHeader>
      {series.map((value, index) => (
        <TableLine color={colorsArr[index]}>
          <div>
            <span></span>
            <div>{labels[index]}</div>
          </div>
          <div>{value}</div>
          <div>{proportion![index]}</div>
          {elapsed ? <div>{elapsed![index]}</div> : null}
        </TableLine>
      ))}
    </Table>
  )
}

export default RatioTable
