import React from "react";
import {
  SingleBlockType,
  SingleBlockChartDataType,
  RadioChartDataType,
} from "../../modules/types";
import { Chart } from "@earlysloth/pocketsurvey-ui-components";

export const makexAxis = (blocks: SingleBlockType[]) => {
  const xAxisArr: string[] = [];
  blocks.map((item, index: number) => {
    xAxisArr.push((item.label.blueprintLabel));
  });
  return xAxisArr;
};

export const makeData = (blocks: SingleBlockType[]) => {
  const scoreArray: any[] = [];
  const makeDataArr = (i: number) => {
    const dataArray: number[] = [];
    blocks.map((item, index: number) => {
      const seriesData =
        (item.data as RadioChartDataType).series[i] === null
          ? 0
          : (item.data as RadioChartDataType).series[i];
      dataArray.push(seriesData);
      //@ts-ignore 
      scoreArray.push(item.data.score);
    });
    return {
      dataArray
    };
  };

  const labels: string[] = [];
  const series: number[][] = [];
  for (
    let i = 0;
    i < (blocks[0].data as SingleBlockChartDataType).series.length;
    i++
  ) {
    const { dataArray } = makeDataArr(i);
    series.push(dataArray);
    labels.push((blocks[0].data as RadioChartDataType).labels[i]);
  }
  return {
    labels,
    series,
    line: [
      {
        name: "점수",
        series: scoreArray,
      },
    ],
  };
};

const getOption = (blocks: SingleBlockType[]) => {
  const chartOptions = makeData(blocks);

  const override = {
    stroke: {
      width: [0, 2],
    },
    dataLabels: {
      enabled: true,
      background: {
        enabled: true,
        foreColor: "black",
        borderColor: "black",
        borderWidth: 0.1,
        opacity: 0.7,
        dropShadow: {},
      },
      style: {
        colors: ["#fdeeb3", "#59c4db"], // 막대 글씨 컬러, 선 컬러
      },
    },
    yAxis: [
      {
        type: 'value',
        name: '응답률(%)',
        nameLocation: 'middle',
        nameGap: 35,
        min: 0,
        max: 100,
        interval: 10,
      },
      {
        opposite: true,
        type: 'value',
        name: '점수',
        min: 0,
        max: 100,
        position: 'right',
        interval: 10,
        axisLine: {
          onZero: 0,
        },
        nameLocation: 'middle',
        nameGap: 35,
      },
    ],
  };

  const xAxisData = makexAxis(blocks)
  const lineData = chartOptions?.line.map((line) => ({
    name: line.name,
    series: line.series.filter((item, index) => index < xAxisData.length),
  }));


  return {
    labels: chartOptions ? chartOptions.labels : [],
    line: chartOptions ? lineData : [],
    series: chartOptions ? chartOptions.series : [],
    xAxisLabel: makexAxis(blocks),
    override,
  };
};

type MixedChartPropsType = {
  data: SingleBlockType[];
  colors?: string[];
  labelOption?: "dynamic" | "fixed";
};

const MixedChart = ({ data, colors, labelOption }: MixedChartPropsType) => {
  const option = getOption(data);
  return (
    <Chart.BarVerticalStacked
      series={option.series}
      labels={option.labels}
      line={option.line}
      xAxisLabel={option.xAxisLabel}
      override={option.override}
      width={"482px"}
      height={"330px"}
      hundredPercent={{
        series: true,
        tooltip: true,
      }}
    />
  );
};

export default MixedChart;
