import React from 'react'
import {
  CheckChartDataType,
  RadioChartDataType,
} from '../../../modules/types'
import { Chart } from '@earlysloth/pocketsurvey-ui-components'

type lineChartPropsType = {
  chartData: RadioChartDataType | CheckChartDataType
  labelOption: 'dynamic' | 'fixed'
}

function LineChart({
  chartData: { question, labels, series },
  labelOption,
}: lineChartPropsType) {
  return (
    <Chart.LineBase
      series={series}
      labels={labels}
      hasMarker={true}
      width={"482px"}
      height="280px"
    />
  )
}

export default LineChart
