import { getUrlSplitRegex } from ".";

/**
 * @author pp jo <pp.jo@earlysloth.com>
 * @description url에서 namespace와 urlToken을 뽑아주는 함수
 * @param {string} url 뷰어 애플리케이션에 들어올 때 보이는 url
 * @returns namespace와 보고서 urlToken을 반환
 */
function getNameSpaceAndUrlTokenFromUrl(url: string) {
  const urlSplitRegex = getUrlSplitRegex(url);
  const splitedUrl = url.match(urlSplitRegex);

  return [splitedUrl![1], splitedUrl![2]];
}

export default getNameSpaceAndUrlTokenFromUrl;
