import Api from "@src/Api"

type ReportStatusType =
  | {
      protected: false
    }
  | string

/**
 * @author pp jo <pp.jo@earlysloth.com>
 * @description 보고서의 상태를 확인
 * @param {string} namespace 계정 username
 * @param {string} urlToken 보고서 고유 url token
 * @returns 404 or 보고서 비밀번호 사용 여부 반환
 */
async function checkReportStatus(namespace: string, urlToken: string) {
  try {
    const { data, status } = await Api.get<ReportStatusType>(`/viewer/${namespace}/${urlToken}`)
    return [data, status]
  } catch (e) {
    return [undefined, 404]
  }
}

export default checkReportStatus
