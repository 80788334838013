import Api from "@src/Api"

/**
 * @author pp jo <pp.jo@earlysloth.com>
 * @description block을 표시하기 위해 필요한 데이터를 호출하는 함수
 * @param {string} namespace 계정 username
 * @param {string} urlToken 보고서 고유 url token
 * @param {string} callHash 블록을 표시하기 위해 필요한 데이터의 키
 * @param {string} surveyId 블록을 표시하기 위해 필요한 서베이 Id
 * @returns 차트 데이터
 */
async function getBlockDataFromCallHash(namespace: string, urlToken: string, callHash: string, surveyId: string) {
  const { data, status } = await Api.post<any>(`/chart-call-read/${namespace}/${urlToken}/`, {
    call_hash: callHash,
    survey_id: surveyId,
  })

  return [data, status]
}

export default getBlockDataFromCallHash
