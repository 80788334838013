import { getBlockDataFromCallHash } from "@src/service";
import { call } from "redux-saga/effects";
import { SingleBlockContentType, SingleFreqType } from "../types";

function* setSingleFrequencyBlockDataWorker(
  namespace: string,
  urlToken: string,
  block: SingleBlockContentType,
  isMulti: boolean
) {
  const callHashs = block.callHash as string[];
  let frequencyDatas = [];

  for (const hash of block.callHash) {
    const [data, status]: [any, number] = yield call(
      getBlockDataFromCallHash,
      namespace,
      urlToken,
      hash,
      block.surveyId
    );
    const parsedData = data[data.question_orders[0]];

    frequencyDatas.push(parsedData);
  }

  const newSingleFreqBlock: SingleFreqType = frequencyDatas;

  return newSingleFreqBlock;
}

export default setSingleFrequencyBlockDataWorker;
