import Api from "@src/Api";
import { ReportDataType } from "@modules/types";

/**
 * @author pp jo <pp.jo@earlysloth.com>
 * @description 보고서의 정보를 가져옵니다. 비밀번호가 있거나 없을 수 있음
 * @param {string} namespace 계정 username
 * @param {string} urlToken 보고서 고유 url toke
 * @param {string} password 보고서 비밀번호
 * @returns 보고서 정보가 JSON으로 표현되어 반환
 */
async function getReportData(
  namespace: string,
  urlToken: string,
  password?: string
) {
  const { data, status } = await Api.post<ReportDataType>(
    `/viewer/${namespace}/${urlToken}`,
    password ? { password } : null
  );

  return [data, status];
}

export default getReportData;
