import { MultiBlockType, SingleBlockType } from "../../modules/types";
import MixedChart from "./MixedChart";
import MultiplebarA from "./MultiplebarA";
import MultiplebarB from './MultiplebarB'
import SingleBarChart from "./SingleBarChart";

export const switchMultiChart = (info: MultiBlockType) => {
  switch (info.chartType) {
    case "mixed":
      return <MixedChart data={info.blocks as SingleBlockType[]} />;

    case "multiplebar/A":
      return <MultiplebarA data={info.blocks as SingleBlockType[]} />;

    case "multiplebar/B": 
    case "separated":
      return <MultiplebarB data={info.blocks} type={info.chartType} /> 

    case "singlebar":
      return <SingleBarChart data={info.blocks as SingleBlockType[]} />;
  }
};

