export enum ChartType {
  pie = "pie",
  pieImage = "pieImage",
  barHorizontal = "barHorizontal",
  barVertical = "barVertical",
  barImage = "barImage",
  line = "line",
  nps = "nps",
  ratioTable = "ratioTable",
  rankTable = "rankTable",
  gauge = "gauge",
  barHorizontalPriority = "barHorizontalPriority",
  barVerticalPriority = "barVerticalPriority",
  barHorizontalRank = "barHorizontalRank",
  barVerticalRank = "barVerticalRank",
  shorttext = "shorttext",
  shorttextImage = "shorttextImage",
  shorttextVideo = "shorttextVideo",
  priorityBarVertical = "priorityBarVertical",
  priorityBarHorizontal = "priorityBarHorizontal",
}

export type BlockType = "single" | "text" | "info" | "multi";

export type NpsRangeType = {
  start: string;
  end: string;
};

export type NpsContentType = {
  ratio: number;
  counts: number;
};

export type NpsChartDataType = {
  question_text: string;
  questionNum?: number;
  range: NpsRangeType[];
  score: number[];
  detractors: NpsContentType[];
  passives: NpsContentType[];
  promoters: NpsContentType[];
  sum_counts: number[];
};

export type SingleBlockContentType = {
  blockType: "single";
  chartType: string;
  callHash: string[];
  label: {
    blueprintLabel: string;
    questionLabel: string;
  };
  surveyId: string;
  colors: string[];
  blockInfo?: {
    from?: string;
    to?: string;
    filter?: {
      q1: number;
      q2: number;
      filterAnswerIndex: number;
    };
    frequencyUnit: number;
  };
  content?: string;
  questionType: "radio" | "check" | "shorttext";
  frequencyRange?: string[];
};

export type MultiBlockContentType = {
  blockType: "multi";
  blockInfo: {
    labels: {
      showBlueprintLabel: boolean;
      showQuestionLabel: boolean;
    };
    labelOption: "fixed" | "dynamic";
  };
  blocks: SingleBlockContentType[];
  label: {
    blueprintLabel: string;
    questionLabel: string;
  };
  chartType: "mixed" | "singlebar" | "multiplebar/A" | "multiplebar/B";
  colors: string[];
  crossTableCallHash?: {
    //multipleB에서 호출할 데이터
    call_hash: string;
    survey_id: string;
  };
};

export type TextBlockContentType = {
  blockType: "text";
  content: string;
  label: {
    blueprintLabel: string;
    questionLabel: string;
  };
};

export type ContentsType =
  | SingleBlockContentType
  | MultiBlockContentType
  | TextBlockContentType;

export type ReportDataType = {
  title: string;
  urlToken: string;
  colors: string[];
  contents: ContentsType[];
};

export type SingleBlockChartDataType =
  | RadioChartDataType
  | CheckChartDataType
  | ShorttextChartDataType;

export type RadioChartDataType = {
  question: string;
  questionNum?: number;
  type?: string;
  series: number[];
  labels: string[];
  proportion?: string[];
  elapsed?: string[];
  image_selections?: never[];
  weightedRankingScores?: {
    weight_score: number[];
    weight_score_100: number[];
    sel_ranges: {
      min: number;
      max: number;
    };
    is_check_ranking: boolean;
  };
  selectionRankingIndex?: {
    [key: string]: number;
  } | null;
  categorizedRankingStacks?: number[][];
  score?: number | null;
};
export type CheckChartDataType = {
  question: string;
  questionNum?: number;
  type?: string;
  series: number[];
  labels: string[];
  proportion?: string[];
  elapsed?: string[];
  image_selections?: never[];
  weightedRankingScores?: {
    weight_score: number[];
    weight_score_100: number[];
    sel_ranges: {
      min: number;
      max: number;
    };
    is_check_ranking: boolean;
  };
  selectionRankingIndex?: {
    [key: string]: number;
  } | null;
  categorizedRankingStacks?: number[][];
  score?: number | null;
};
export type ShorttextChartDataType = {
  question: string;
  type: "shorttext";
  questionNum: number;
  tags: string[][];
  tags_kv: string[][];
  phonenum: string[];
  series: string[];
  scale: {
    question_title: string[];
    score_of_question: number[];
    question_num: number[];
    n_size: number;
    ranks: number[];
    max_scores: number[];
    overall_score: number;
    score_of_question_100: number[];
    overall_score_100: number;
  }[];
  uuid: string[];
};

export type TextBlockDataType = {
  text: string;
};

export type SingleBasicType = SingleBlockChartDataType;
export type SingleFreqType = (RadioChartDataType | CheckChartDataType)[];

export type CrossTableChartInnerType = {
  headers: {
    column: string[];
    row: string;
  };
  question_numbers: {
    column: number;
    row: number;
  };
  question_orders: {
    column: 5;
    row: 0;
  };
  question_texts: {
    column: string;
    row: string;
  };
  table_data: number[][];
};
export type CrossTableChartType = {
  counts: CrossTableChartInnerType;
  ratios: CrossTableChartInnerType;
  scores: number[];
  scores_mean: number;
};

export type SingleBlockType = {
  blockInfo: {
    date?: {
      from?: string;
      to?: string;
    };
    filter?: {
      q1: number;
      q2: number;
      filterAnswerIndex: number;
    };
    labelOption?: "dynamic" | "fixed";
    frequencyUnit?: number;
  };
  blockType: "single";
  chartType: string;
  questionType: "radio" | "check" | "shorttext";
  data: SingleBasicType | SingleFreqType;
  label: {
    blueprintLabel: string;
    questionLabel: string;
  };
  colors?: string[];
  frequencyRange?: string[];
};

export type TextBlockType = {
  blockType: "text";
  data: TextBlockDataType;
  label: {
    blueprintLabel: string;
    questionLabel: string;
  };
};

export type MultiBlockChartType =
  | "mixed"
  | "singlebar"
  | "multiplebar/A"
  | "multiplebar/B"
  | "separated";

export type MultiBlockType = {
  blockType: "multi";
  chartType: MultiBlockChartType | null;
  blocks: (SingleBlockType | CrossTableChartType)[];
  label: {
    blueprintLabel: string;
    questionLabel: string;
  };
  labelOption: "dynamic" | "fixed";
  colors?: string[];
};

export type InfoBlockType = {
  blockType: "info";
};

export type BlockArrItemType =
  | SingleBlockType
  | TextBlockType
  | MultiBlockType
  | InfoBlockType;

export type BlockArrType = BlockArrItemType[];

export type ContentsDataType = {
  blueprintNumber: number;
  versionNumber: number;
  questionNumber: number;
  chartType: string;
};
