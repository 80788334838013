import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { takeEvery, call, put } from "redux-saga/effects";
import { enableES5 } from "immer";
import { getBlockDataFromCallHash } from "@src/service";
import {
  BlockArrItemType,
  BlockArrType,
  CrossTableChartType,
  MultiBlockType,
  ReportDataType,
  SingleBlockChartDataType,
  SingleBlockType,
  SingleFreqType,
  TextBlockType,
} from "./types";
import { getNameSpaceAndUrlTokenFromUrl } from "./utils";
import {
  checkReportStatusWorker,
  getReportDataWorker,
  getSingleBlockChartData,
} from "./generators";

enableES5();

export type SandboxStateType = {
  reportData: ReportDataType | null;
  blockArr: BlockArrType;
  isOpenPasswordModal: boolean;
  isZeroData: boolean;
};

export const sandboxInitialState: SandboxStateType = {
  reportData: null,
  blockArr: [],
  isOpenPasswordModal: false,
  isZeroData: false,
};

/**
 * @author pp jo <pp.jo@earlysloth.com>
 * @description 샌드박스 뷰어 파트 엔트리 포인트
 * @param {string} payload url 데이터
 */
function* reportInitializer({ payload }: { payload: string }) {
  try {
    const [namespace, urlToken] = getNameSpaceAndUrlTokenFromUrl(payload);
    const [data, status]: [any, number] = yield call(
      checkReportStatusWorker,
      payload
    );

    if (status == 200) {
      const reportData: [ReportDataType, number] = yield call(
        getReportDataWorker,
        namespace,
        urlToken
      );
      document.title = reportData[0].title;
      yield put(sandboxActionsCreators.setReportData(reportData[0]));

      for (
        let itemIndex = 0;
        itemIndex < reportData[0].contents.length;
        itemIndex++
      ) {
        const ABlock = reportData[0].contents[itemIndex];
        if (ABlock.blockType === "single") {
          const chartData: SingleFreqType | SingleBlockChartDataType =
            yield call(
              getSingleBlockChartData,
              ABlock,
              namespace,
              urlToken,
              false
            );

          const newBlock: SingleBlockType = {
            blockType: "single",
            chartType: ABlock.chartType,
            questionType: ABlock.questionType,
            blockInfo: ABlock.blockInfo!,
            data: chartData,
            label: ABlock.label,
            colors: ABlock.colors,
            frequencyRange: ABlock.frequencyRange,
          };

          yield put(sandboxActionsCreators.addblockArr(newBlock));
        } else if (ABlock.blockType == "text") {
          let newData: TextBlockType = {
            blockType: "text",
            data: { text: ABlock.content! },
            label: ABlock.label,
          };

          yield put(sandboxActionsCreators.addblockArr(newData));
        } else if (ABlock.blockType == "multi") {
          let newBlock: MultiBlockType = {
            blockType: "multi",
            chartType: ABlock.chartType,
            blocks: [],
            label: ABlock.label,
            labelOption: ABlock.blockInfo.labelOption,
            colors: ABlock.colors,
          };

          if (ABlock.crossTableCallHash) {
            const [crossTableChartData, status]: [CrossTableChartType, number] =
              yield call(
                getBlockDataFromCallHash,
                namespace,
                urlToken,
                ABlock.crossTableCallHash.call_hash,
                ABlock.crossTableCallHash.survey_id
              );

            newBlock.blocks.push(crossTableChartData);
          }

          for (
            let singleBlockIndex = 0;
            singleBlockIndex < ABlock.blocks.length;
            singleBlockIndex += 1
          ) {
            let singleBlock = ABlock.blocks[singleBlockIndex];

            const chartData: SingleFreqType | SingleBlockChartDataType =
              yield call(
                getSingleBlockChartData,
                singleBlock,
                namespace,
                urlToken,
                true,
                itemIndex,
                singleBlockIndex
              );

            const newSingleBlock: SingleBlockType = {
              blockType: "single",
              chartType: singleBlock.chartType,
              questionType: singleBlock.questionType,
              blockInfo: singleBlock.blockInfo!,
              data: chartData,
              label: singleBlock.label,
              colors: singleBlock.colors,
            };

            newBlock.blocks.push(newSingleBlock);
          }

          yield put(sandboxActionsCreators.addblockArr(newBlock));
        }
      }
    } else if (status == 404) {
      // window.location.assign("https://home.pocketsurvey.co.kr/404")
      // console.log("비밀번호를 입력받는 모달을 켜야 함")
      // yield put(sandboxActionsCreators.setIsOpenPasswordModal(true))
    }
  } catch (e) {
    console.error(e);
  }
}

export const sandboxSlice = createSlice({
  name: "sandbox",
  initialState: sandboxInitialState,
  reducers: {
    fetchReportData(state, action) {},
    setReportData(state, action: PayloadAction<ReportDataType>) {
      state.reportData = action.payload;
    },
    addblockArr(state, action: PayloadAction<BlockArrItemType>) {
      state.blockArr.push(action.payload);
    },
    setIsOpenPasswordModal(state, action: PayloadAction<boolean>) {
      state.isOpenPasswordModal = action.payload;
    },
  },
});

// blueprint 데이터 가져오는 워커
export function* sandboxSaga() {
  const { fetchReportData } = sandboxSlice.actions;
  yield takeEvery(fetchReportData, reportInitializer);
}

export const sandboxActionsCreators = {
  ...sandboxSlice.actions,
};

export const sandboxReducer = sandboxSlice.reducer;
