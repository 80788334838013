import React from "react";
import styled from "styled-components";
import {
  PieChart,
  LineChart,
  GaugeChart,
  BarChart,
  BarRankChart,
  FrequencyChart,
  ShorttextChart,
} from "./Charts";
import RatioTable from "./Tables/RatioTable";
import RankTable from "./Tables/RankTable";
import {
  ChartType,
  CheckChartDataType,
  RadioChartDataType,
  ShorttextChartDataType,
  SingleBlockType,
  SingleFreqType,
} from "../../modules/types";

const SingleDataChartDiv = styled.div`
  background: rgb(255, 255, 255);
  box-shadow: #dfdedd -1px 1px 5px;
  z-index: -1;
  padding: 28px 14px;
  @media (max-width: 301px) {
    border-radius: 0px;
  }
  @media (min-width: 301px) and (max-width: 500px) {
    border-radius: 0px;
  }
  width: 100%;
  border-radius: 10px;
  margin-bottom: 14px;
  box-sizing: border-box;
`;
const SingleDataContainerName = styled.h1`
  color: #2b2e33;
  font-size: 20px;
  text-align: left;
  word-break: break-word;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 500;
  margin: 0px;
`;

const QuestionTitle = styled.h2`
  color: #2b2e33;
  font-size: 14px;
  text-align: left;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 400;
  margin-top: 7px;
  margin-bottom: 0px;
`;

const QuestionTitle2 = styled.h2`
  color: #2b2e33;
  font-size: 14px;
  text-align: left;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 500;
  margin-top: 0;
`;

const ChartContainer = styled.div`
  min-height: 250px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-top: auto;
  margin-right: auto;
  margin-bottom: auto;
  overflow-y: hidden;
`;

const InnerScrollDiv = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 35px;
`;

function switchRadioChart(
  chartData: RadioChartDataType,
  chartType: string,
  colors: string[],
  labelOption: "dynamic" | "fixed"
) {
  if (chartData.series.every((item) => item === 0)) {
    return <h1>데이터가 없습니다</h1>
  }
  switch (chartType) {
    case ChartType.pie:
      return (
        <PieChart
          chartData={chartData}
          colors={colors}
          labelOption={labelOption}
        />
      );
    case ChartType.pieImage:
      return (
        <PieChart
          chartData={chartData}
          showImage={true}
          labelOption={labelOption}
        />
      );
    case ChartType.barHorizontal:
      return (
        <BarChart
          chartData={chartData}
          direction="horizontal"
          xAxis={[
            {
              type: "category",
              axisTick: {
                show: true,
              },
              data: chartData.labels,
              splitLine: {
                lineStyle: {
                  color: "#F0F0F0",
                },
              },
            },
          ]}
          yAxis={[
            {
              type: "value",
              splitLine: {
                lineStyle: {
                  color: "#F0F0F0",
                },
              },
            },
          ]}
          colors={colors}
          labelOption={labelOption}
        />
      );
    case ChartType.barVertical:
      return (
        <BarChart
          chartData={chartData}
          direction="vertical"
          xAxis={[
            {
              type: "category",
              axisTick: {
                show: true,
              },
              data: chartData.labels,
              splitLine: {
                lineStyle: {
                  color: "#F0F0F0",
                },
              },
            },
          ]}
          yAxis={[
            {
              type: "value",
              splitLine: {
                lineStyle: {
                  color: "#F0F0F0",
                },
              },
            },
          ]}
          colors={colors}
          labelOption={labelOption}
        />
      );
    case ChartType.barImage:
      return (
        <BarChart
          chartData={chartData}
          direction="horizontal"
          xAxis={[
            {
              type: "category",
              axisTick: {
                show: true,
              },
              data: chartData.labels,
              splitLine: {
                lineStyle: {
                  color: "#F0F0F0",
                },
              },
            },
          ]}
          yAxis={[
            {
              type: "value",
              splitLine: {
                lineStyle: {
                  color: "#F0F0F0",
                },
              },
            },
          ]}
          colors={colors}
          labelOption={labelOption}
        />
      );
    case ChartType.line:
      return <LineChart chartData={chartData} labelOption={labelOption} />;
    // case ChartType.nps:
    //   return <NpsChart chartData={chartData} />
    case ChartType.ratioTable:
      return <RatioTable chartData={chartData} />;
    case ChartType.gauge:
      return (
        <GaugeChart
          chartName="게이지 차트 테스트"
          dataName="오늘 날씨 맑음"
          dataValue={77}
          color="skyBlue"
          height={500}
        />
      );
    default:
      break;
  }
}

function switchCheckChart(
  chartData: CheckChartDataType,
  chartType: string,
  colors: string[],
  labelOption: "dynamic" | "fixed"
) {
  if (chartData.series.every((item) => item === 0)) {
    return <h1>데이터가 없습니다</h1>
  }
  switch (chartType) {
    case ChartType.barHorizontal:
      return (
        <BarChart
          chartData={chartData}
          direction="horizontal"
          xAxis={[
            {
              type: "category",
              axisTick: {
                show: true,
              },
              data: chartData.labels,
              splitLine: {
                lineStyle: {
                  color: "#F0F0F0",
                },
              },
            },
          ]}
          yAxis={[
            {
              type: "value",
              splitLine: {
                lineStyle: {
                  color: "#F0F0F0",
                },
              },
            },
          ]}
          colors={colors}
          labelOption={labelOption}
        />
      );
    case ChartType.barVertical:
      return (
        <BarChart
          chartData={chartData}
          direction="vertical"
          xAxis={[
            {
              type: "category",
              axisTick: {
                show: true,
              },
              data: chartData.labels,
              splitLine: {
                lineStyle: {
                  color: "#F0F0F0",
                },
              },
            },
          ]}
          yAxis={[
            {
              type: "value",
              splitLine: {
                lineStyle: {
                  color: "#F0F0F0",
                },
              },
            },
          ]}
          colors={colors}
          labelOption={labelOption}
        />
      );
    case ChartType.barHorizontalPriority:
      return (
        <BarChart
          chartData={chartData}
          direction="horizontal"
          xAxis={[
            {
              type: "category",
              axisTick: {
                show: true,
              },
              data: chartData.labels,
              splitLine: {
                lineStyle: {
                  color: "#F0F0F0",
                },
              },
            },
          ]}
          yAxis={[
            {
              type: "value",
              splitLine: {
                lineStyle: {
                  color: "#F0F0F0",
                },
              },
            },
          ]}
          colors={colors}
          labelOption={labelOption}
        />
      );
    case ChartType.barVerticalPriority:
      return (
        <BarChart
          chartData={chartData}
          direction="vertical"
          xAxis={[
            {
              type: "category",
              axisTick: {
                show: true,
              },
              data: chartData.labels,
              splitLine: {
                lineStyle: {
                  color: "#F0F0F0",
                },
              },
            },
          ]}
          yAxis={[
            {
              type: "value",
              splitLine: {
                lineStyle: {
                  color: "#F0F0F0",
                },
              },
            },
          ]}
          colors={colors}
          labelOption={labelOption}
        />
      );
    case ChartType.barHorizontalRank:
      return (
        <BarRankChart
          chartData={chartData}
          direction="horizontal"
          xAxis={[
            {
              type: "category",
              axisTick: {
                show: true,
              },
              data: chartData.labels,
            },
          ]}
          yAxis={[
            {
              type: "value",
            },
          ]}
          colors={[
            "red",
            "green",
            "yellow",
            "pink",
            "violet",
            "purple",
            "skyblue",
          ]}
          labelOption={labelOption}
        />
      );
    case ChartType.barVerticalRank:
      return (
        <BarRankChart
          chartData={chartData}
          direction="vertical"
          xAxis={[
            {
              type: "category",
              axisTick: {
                show: true,
              },
              data: chartData.labels,
            },
          ]}
          yAxis={[
            {
              type: "value",
            },
          ]}
          colors={[
            "red",
            "green",
            "yellow",
            "pink",
            "violet",
            "purple",
            "skyblue",
          ]}
          labelOption={labelOption}
        />
      );
    case ChartType.line:
      return <LineChart chartData={chartData} labelOption={labelOption} />;
    case ChartType.ratioTable:
      return <RatioTable chartData={chartData} />;
    case ChartType.rankTable:
      return <RankTable chartData={chartData} />;
  }
}

function switchShorttextChart(data: ShorttextChartDataType, chartType: string) {
  switch (chartType) {
    case ChartType.shorttext:
      return <ShorttextChart data={data} />;
    case ChartType.shorttextImage:
      return null;
    case ChartType.shorttextVideo:
      return null;
    default:
      return <ShorttextChart data={data} />;
  }
}

function SingleDataChart({
  data,
  chartType,
  label,
  colors,
  questionType,
  blockInfo,
  frequencyRange
}: SingleBlockType) {
  const { blueprintLabel: title, questionLabel: desc } = label;

  if (blockInfo.frequencyUnit != undefined) {
    return (
      <SingleDataChartDiv>
        <SingleDataContainerName>{title}</SingleDataContainerName>
        <QuestionTitle>{desc}</QuestionTitle>
        <InnerScrollDiv>
          <ChartContainer>
            <FrequencyChart
              data={data as SingleFreqType}
              colors={colors!}
              labelOption={blockInfo.labelOption!}
              frequencyRange={frequencyRange}
            />
          </ChartContainer>
        </InnerScrollDiv>
      </SingleDataChartDiv>
    );
  } else {
    switch (questionType) {
      case "radio":
        return (
          <SingleDataChartDiv>
            <SingleDataContainerName>{title}</SingleDataContainerName>
            <QuestionTitle>{desc}</QuestionTitle>
            <InnerScrollDiv>
              <ChartContainer>
                {switchRadioChart(
                  data as RadioChartDataType,
                  chartType,
                  colors!,
                  blockInfo.labelOption!
                )}
              </ChartContainer>
            </InnerScrollDiv>
          </SingleDataChartDiv>
        );
      case "check":
        return (
          <SingleDataChartDiv>
            <SingleDataContainerName>{title}</SingleDataContainerName>
            <QuestionTitle>{desc}</QuestionTitle>
            <InnerScrollDiv>
              <ChartContainer>
                {switchCheckChart(
                  data as CheckChartDataType,
                  chartType,
                  colors!,
                  blockInfo.labelOption!
                )}
              </ChartContainer>
            </InnerScrollDiv>
          </SingleDataChartDiv>
        );
      case "shorttext":
        return (
          <SingleDataChartDiv>
            {/* <SingleDataContainerName>{title}</SingleDataContainerName> */}
            <QuestionTitle2>{desc}</QuestionTitle2>
            {switchShorttextChart(data as ShorttextChartDataType, chartType)}
          </SingleDataChartDiv>
        );
      default:
        return null;
    }
  }
}

export default SingleDataChart;
