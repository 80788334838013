import { checkReportStatus } from "@src/service";
import { call } from "redux-saga/effects";
import { getNameSpaceAndUrlTokenFromUrl } from "../utils";

/**
 * @author pp jo <pp.jo@earlysloth.com>
 * @description 보고서의 상태를 확인하는 로직
 * @param {string} url url에 있는 정보를 활용해서 api 호출
 * @returns 보고서 상태 데이터를 반환
 */
function* checkReportStatusWorker(url: string) {
  const [namespace, urlToken] = getNameSpaceAndUrlTokenFromUrl(url);

  const data: Promise<any> = yield call(checkReportStatus, namespace, urlToken);

  return data;
}

export default checkReportStatusWorker;
